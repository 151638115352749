












































































import Vue from 'vue'
import wAxios from '@/plugins/w/axios'
import collect from 'collect.js'

const api = {
  examTemplates:  (params)                  => wAxios.get_auth(`/v1/teacher/examtemplates`, { params }),
  exam:           (examTemplateId, examId)  =>  wAxios.get_auth(`/v1/teacher/examtemplates/${examTemplateId}/exams/${examId}`),
  stopExam:       (examId)                  => wAxios.post_auth(`/v1/teacher/examtemplates/${examId}/stop`),
  deleteExam:     (examId)                  => wAxios.delete_auth(`/v1/teacher/examtemplates/${examId}`)
}

export default {

  components: {
    'a-exam-share':       () => import('@/plugins/app@exams/_components/exam-share-modal/a-exam-share.vue'),
    'a-exam-duplicate':   () => import('@/plugins/app@exams/_components/exam-duplicate-modal/a-exam-duplicate.vue'),
    'z-list-header':      () => import('@/plugins/app@components/list-header/z-list-header.vue'),
    'z-loader':           () => import('@/plugins/app@components/loader/z-loader.vue'),
    'a-exam-info':        () => import('./a-exam-info.vue'),
    'a-exam-start':       () => import('./a-exam-start.vue')
  },

  data() {
    return {
      loadingExams: false,
      exams: null,
      perPage: 8,
      currentPage: +this.$route.query?.page || 1,
      checked: false,
      totalExams: null,
      selectedExam: null,
      startedExam: null,
      dashboardUrl: ''
    }
  },

  async mounted() {
    await this.loadExams()

    this.$root.$on('bv::modal::hide', (event, modalId) => {
      if (event.trigger && event.trigger !== 'started' && modalId === 'StartModal') {
        this.cancelStart()
      }
    })

    window.onpopstate = (event) => {
      if (event.state && event.state.page) {
        this.loadExams(event.state.page, false)
      } else {
        history.back()
      }
    }
  },

  methods: {

    async showInfoModal(i) {
      await this.selectExam(i)
      try {
        ({ data: { data: this.startedExam } } = await api.exam(
          this.selectedExam.id, this.selectedExam.exams.find(e => e.stopped_at == null)?.id
        ))
        this.showInfo()
      } catch(err) {
        this.$wToast.error(err)
      }
    },

    async onStarted(startedExam) {
      this.startedExam = startedExam
      // without requesting API, push started exam to exams of current exam template
      this.selectedExam.exams.push(startedExam)
      // POZOR ZLY HACK!!
      // zvlastny sposob ako vyvolat custom trigger pri skryti bootstrap modalu
      this.$refs['startModal'].$children[0].hide('started')
      await this.showInfo()
    },

    async showInfo() {
      this.$bvModal.show('InfoModal')
    },

    async toggle(i) {
      await this.selectExam(i)

      if (!this.selectedExam.checked) {
        const value = await this.$bvModal.msgBoxConfirm('Do testu nebude možné vstúpíť ani pokračovať v odpovediach.', {
            title: 'Zastaviť test?',
            okTitle: 'Zastaviť',
            okVariant: 'a-primary',
            cancelTitle: 'Zrušiť',
            cancelVariant: 'a-primary--outline',
            buttonSize: 'sm',
            hideHeaderClose: false,
        })
        if (value) {
          try {
            await api.stopExam(this.selectedExam.id)
            this.$bvToast.toast(
              `Do testu nie je možné vstúpíť ani pokračovať v odpovediach`,
              {
                title: 'Test je zastavený',
                autoHideDelay: 5000,
                variant: 'a-success',
                toaster: 'b-toaster-top-center',
                solid: true
              }
            )
            await this.loadExams()
          } catch (err) {
            this.$wToast.error(err)
          }
        } else {
          this.selectedExam.checked = true
        }
      } else {
        this.$bvModal.show('StartModal')
      }
    },

    async loadExams(event, pushState = true) {
      const page = (event != null && event != undefined) ? event : this.currentPage
      this.loadingExams = true
      try {
        ({ data: { meta: { total: this.totalExams }, data: this.exams }} = await api.examTemplates({ page, limit: 8 }))
        const exams = collect<any>(this.exams).all()
        exams.forEach(element => {
          const isStarted = collect(element.exams).where('stopped_at', null).first()
          if (isStarted) {
            this.$set(element, 'checked', true)
          } else {
            this.$set(element, 'checked', false)
          }
        })
        this.currentPage = page
        if (pushState) {
          history.pushState(
              { page: this.currentPage},
              '',
              `${location.origin}${location.pathname}?page=${this.currentPage}`
          )
        }
      } catch(err) {
        this.$wToast.error(err)
      } finally {
        this.loadingExams = false
      }
    },

    async deleteExam(i) {
      await this.selectExam(i)
      try {
        const confirm = await this.$bvModal.msgBoxConfirm('Naozaj chcete vymazať tento test?', {
          title: 'Vymazať test?',
          okTitle: 'Vymazať',
          okVariant: 'a-primary',
          cancelTitle: 'Zrušiť',
          cancelVariant: 'a-primary--outline',
          buttonSize: 'sm',
          hideHeaderClose: false,
        })

        if (confirm) {
          await api.deleteExam(this.selectedExam.id)
          await this.loadExams()
        }
      } catch(err) {
        this.$wToast.error(err)
      }
    },

    async duplicateExam(i) {
      await this.selectExam(i)
      this.$bvModal.show('DuplicateModal')
    },

    async shareExam(i) {
      await this.selectExam(i)
      this.$bvModal.show('ShareModal')
    },

    async selectExam(i) {
      if (i == undefined) {
        return
      }

      this.selectedExam = this.exams[i]
      await Vue.nextTick()
    },

    cancelStart() {
      const exam = collect<any>(this.exams).where('id', this.selectedExam?.id).first()
      if (exam) {
        exam.checked = false
      }
    },

    async logout() {
      try {
        await this.$store.dispatch('wAuth/logout')
        await this.$router.push({name: 'Home'})
      } catch(err) {
        console.error(err)
      }
    }
  }
}
